import { GlossaryList } from './glossary';
import { TranslationEngineWithLanguageMapping } from './translatable';

export type TranslateEngineItem = {
  _id?: string;
  org_id: string;
  priority?: number;
  createdBy: string;
  glossary: GlossaryList | null;
  translationEngine: string;
  translationEngineStatus?: boolean;
  name: string;
  apikey?: string;
  lastModifiedBy?: string;
  customEngineAuthentication?: string;
  url?: string;
  customEngineMethod?: string;
  username?: string;
  password?: string;
  tokenPrefix?: string;
  bearerToken?: string;
  authURL?: string;
  tokenURL?: string;
  callbackURL?: string;
  clientID?: string;
  clientSecret?: string;
  scope?: string;
  accessToken?: string;
  redirecturi?: string;
  selectedlanguageisocodes?: string[];
  batchSize?: string;
  isCustomTranslationEngine: boolean;
  desiredStructure?: string;
  aws_access_key_id?: string;
  aws_secret_access_key?: string;
  google_cloud_project_id?: string;
  google_cloud_location?: string;
};

export type TranslateEngine = {
  _id: string;
  id: string;
  org_id: string;
  glossary: GlossaryList;
  createdBy: string;
  createdDate: string;
  translationEngine: string;
  apikey?: string;
  name: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  translationEngineStatus: boolean;
  url?: string;
  customEngineMethod?: string;
  username?: string;
  password?: string;
  tokenPrefix?: string;
  bearerToken?: string;
  authURL?: string;
  tokenURL?: string;
  callbackURL?: string;
  clientID?: string;
  clientSecret?: string;
  scope?: string;
  accessToken?: string;
  redirecturi?: string;
  customEngineAuthentication?: string;
  isCustomTranslationEngine: boolean;
  selectedlanguageisocodes?: string[];
  batchSize?: string;
  desiredStructure?: string;
  aws_access_key_id?: string;
  aws_secret_access_key?: string;
  google_cloud_project_id?: string;
  google_cloud_location?: string;
  refresh_token?: string;
  priority?: string;
};
export type TranslateEngineWithLanguageMapping = {
  _id: string;
  org_id: string;
  name: GlossaryList;
  translationEngine: string;
  languageMappingStatus: { isoCode: string; isMapped: boolean }[];
  //   translationEngineStatus: boolean;
};

export type TranslationEngineTestResponse = {
  response_code: string;
  response_body: string | any;
  request_body: any;
  request_url: string | undefined;
};

export type EngineDataWithLanguagePair = {
  org_id: string;
  _id?: string;
  id?: string;
  env_id: string | undefined;
  masterLanguage: string | undefined;
  mappedTranslationEngine?: TranslationEngineWithLanguageMapping[];
};

export const DEFAULT_GLOSSARY_NAME = 'ST Default Glossary';

export const DEFAULT_TRANSLATE_ENGINE = 'Google Translate';

export const translationEngineOptions = [
  { label: 'Google Translate', value: 'Google Translate' },
  { label: 'Open AI/Chat GPT', value: 'Chat-GPT' },
  { label: 'DeepL', value: 'DeepL' },
  { label: 'AWS', value: 'AWS' },
  { label: 'Custom Translate Engine', value: 'Custom-Engine' },
];
