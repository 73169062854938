import { useCallback, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
// hooks
import { useDashboardContext } from 'src/context/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import useFetcher, { endpoints } from 'src/utils/axios';
import Iconify from 'src/components/iconify';
import WizzardDialog from './wizard-dialog';

// ----------------------------------------------------------------------

type Props = {
  wizardStatus: string;
};

export default function SetupWizardIcon({ wizardStatus }: Props) {
  const { currentOrg, setCurrentOrgs } = useDashboardContext();
  const [newWizzardStatus, setNewWizzardStatus] = useState(wizardStatus);
  const { updateData } = useFetcher();
  const showDialog = useBoolean();
  const isCompleted = useBoolean(false);

  const theme = useTheme();

  const handleWizzardUpdate = useCallback(
    (status: string) => {
      if (currentOrg) {
        const updatedOrg = { ...currentOrg, setupWizzardStatus: status };
        updateData(endpoints.org.put, updatedOrg);
        setCurrentOrgs(updatedOrg);
        setNewWizzardStatus(status);
      }
    },
    [currentOrg, updateData, setCurrentOrgs]
  );

  useEffect(() => {
    if (wizardStatus === 'initial') {
      handleWizzardUpdate('started');
      showDialog.onTrue();
    }
    if (wizardStatus === 'credSetupFinished') {
      showDialog.onTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardStatus]);

  return (
    <>
      {!isCompleted.value && (
        <Badge
          onClick={showDialog.onTrue}
          component={Button}
          sx={{
            right: 15,
            bottom: 20,
            zIndex: 999,
            display: 'flex',
            cursor: 'pointer',
            position: 'fixed',
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            bgcolor: theme.palette.primary.main,
            '&:hover': { bgcolor: theme.palette.primary.dark },
          }}
        >
          <Iconify icon="ri:guide-line" color="white" width={30} />
        </Badge>
      )}
      <WizzardDialog
        open={showDialog.value}
        onClose={showDialog.onFalse}
        wizardStatus={newWizzardStatus}
        wizardCompleted={isCompleted.onTrue}
      />
    </>
  );
}
