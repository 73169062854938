import { useEffect, useState } from 'react';
// types
import { IEnvironment } from 'src/types/orgCredential';
// hooks
import { useDashboardContext, useRealTimeContext } from 'src/context/hooks';
//
import useFetcher, { endpoints } from 'src/utils/axios';
// ----------------------------------------------------------------------

const stepStatusPagesMap: Record<string, number> = {
  started: 0,
  setPrerequisites: 1,
  credentialSetup: 2,
  retrieveTranslation: 3,
  translationEngine: 4,
  finished: 5,
};

export default function useSetupWizardHandler(): SetupWizardHandler {
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const [newWizardStatus, setNewWizardStatus] = useState('');
  const [activeStep, setActiveStep] = useState(-1);

  const { fetchData, updateData } = useFetcher();
  const { realTimeMessages } = useRealTimeContext();
  const { currentOrg, setCurrentOrgs } = useDashboardContext();

  useEffect(() => {
    if (realTimeMessages && currentOrg) {
      if (realTimeMessages[0]?.message === 'refresh_data') {
        fetchEnv();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMessages]);

  const fetchEnv = () => {
    fetchData(endpoints.environment).then((env) => {
      setEnvironments(env);
    });
  };

  useEffect(() => {
    if (currentOrg) {
      setNewWizardStatus(currentOrg.setupWizzardStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);

  const handleNext = () => {
    const newStep = activeStep + 1;
    setActiveStep(newStep);
    saveWizzardStatus(newStep);
  };

  const handleBack = () => {
    const newStep = activeStep - 1;
    setActiveStep(newStep);
    saveWizzardStatus(newStep);
  };

  const saveWizzardStatus = async (step: number) => {
    const currentStepStatus = Object.keys(stepStatusPagesMap).find(
      (key) => stepStatusPagesMap[key] === step
    );
    if (currentStepStatus && currentOrg) {
      const updatedOrg = { ...currentOrg, setupWizzardStatus: currentStepStatus };
      setNewWizardStatus(currentStepStatus);
      setCurrentOrgs(updatedOrg);
      updateData(endpoints.org.put, updatedOrg);
    }
  };

  const handleReset = () => {
    setActiveStep(1);
    saveWizzardStatus(1);
  };

  const setFinishStatus = () => {
    if (currentOrg?.org_id) {
      const updatedOrg = { ...currentOrg, setupWizzardStatus: 'completed' };
      setCurrentOrgs(updatedOrg);
      updateData(endpoints.org.put, updatedOrg);
    }
  };

  return {
    environments,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    handleReset,
    setFinishStatus,
    newWizardStatus,
  };
}

export type SetupWizardHandler = {
  environments: IEnvironment[];
  activeStep: number;
  setActiveStep: (step: number) => void;
  handleNext: () => void;
  handleBack: () => void;
  handleReset: () => void;
  setFinishStatus: () => void;
  newWizardStatus: string;
};
