import { useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// types
import { ILanugage } from 'src/types/translatable';
import { IEnvironment } from 'src/types/orgCredential';
import { languages } from 'src/assets/data/languages';
// components
import { useDashboardContext } from 'src/context/hooks';
import Iconify from 'src/components/iconify';
import useFetcher, { endpoints } from 'src/utils/axios';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Label from 'src/components/label';
import EnvQuickEditForm from './environment-quick-edit-form';
import RetrieveLanguagesInfoMessage from './retrieve-languages-info-from';

// ----------------------------------------------------------------------

type Props = {
  row: IEnvironment;
  selected: boolean;
  onViewRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: (id: string) => void;
  onUpdateRow: (updatedRow: IEnvironment) => void;
  handleEdit: (id: string) => void;
};

export default function EnvironmentTableRowMetadata({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  onUpdateRow,
  handleEdit,
}: Props) {
  const { name, instance_url, api_name, type, status } = row;
  const { currentOrg } = useDashboardContext();
  const { deleteData, updateData } = useFetcher();
  const [languagesList, setLanguagesList] = useState<ILanugage[]>([]);
  const quickEdit = useBoolean();
  const confirm = useBoolean();
  const popover = usePopover();
  const LanguagePopover = usePopover();
  const isRetrieveLanguageInfo = useBoolean();

  const handleDelete = () => {
    onDeleteRow(row.env_id);
    confirm.onFalse();
  };
  const deactivateUserEnv = () => {
    const requestBody = {
      ...row,
      status: 'inactive',
    };
    deleteData([endpoints.environment, { params: { env_id: row?.env_id } }]).then(() => {
      onUpdateRow(requestBody);
    });
  };

  const activateUserEnv = () => {
    const requestBody = {
      ...row,
      status: 'active',
    };
    updateData(endpoints.environment, requestBody).then(() => {
      onUpdateRow(requestBody);
    });
  };
  useEffect(() => {
    if (currentOrg && row.languages) {
      const filteredLanguages = languages.filter((Ilang) => row.languages.includes(Ilang.isoCode));
      setLanguagesList(filteredLanguages);
    } else {
      setLanguagesList([]);
    }
  }, [currentOrg, row.languages]);
  const renderLanguages = (
    <CustomPopover
      open={LanguagePopover.open}
      onClose={LanguagePopover.onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      arrow="left-top"
      sx={{ width: 160 }}
    >
      {languagesList.slice(1).map((lang) => (
        <MenuItem
          onClick={() => {
            LanguagePopover.onClose();
          }}
        >
          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${lang.countryCode.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${lang.countryCode.toLowerCase()}.png 2x`}
              alt={`${lang.label} flag`}
              style={{ marginRight: 8 }}
            />
            {lang.label}
          </Box>
        </MenuItem>
      ))}
    </CustomPopover>
  );
  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell>
        <Box>{name}</Box>
      </TableCell>
      <TableCell>
        <Box>{api_name}</Box>
      </TableCell>
      <TableCell>
        <Box>{type}</Box>
      </TableCell>
      <TableCell>
        <Box>{instance_url}</Box>
      </TableCell>
      <TableCell>
        {languagesList[0] ? (
          <MenuItem onClick={LanguagePopover.onOpen}>
            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${languagesList[0].countryCode.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${languagesList[0].countryCode.toLowerCase()}.png 2x`}
                alt={`${languagesList[0].label} flag`}
                style={{ marginRight: 8 }}
              />
              {languagesList[0].label}{' '}
              {languagesList.length > 1 && `(+${languagesList.slice(1).length})`}
            </Box>
          </MenuItem>
        ) : (
          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            <MenuItem
              onClick={() => {
                isRetrieveLanguageInfo.onTrue();
              }}
            >
              No Languages available!
              <Iconify
                icon="material-symbols:info-outline"
                sx={{ width: 24, color: 'info.main' }}
                aria-label="info"
              />
            </MenuItem>
          </Box>
        )}
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Label
          variant="soft"
          color={
            (status === 'active' && 'success') || (status === 'inactive' && 'error') || 'default'
          }
        >
          {status}
        </Label>
      </TableCell>
      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <Tooltip title="Edit Environment" placement="top" arrow>
          <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const renderPopover = (
    <CustomPopover
      open={popover.open}
      onClose={popover.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      arrow="left-top"
      sx={{ width: 180 }}
    >
      <MenuItem
        onClick={() => {
          popover.onClose();
          window.open(`${instance_url}/lightning/setup/LanguageSettings/home`, '_blank');
        }}
      >
        <Iconify icon="dashicons:translation" width={16} />
        Languages Settings
      </MenuItem>
      <MenuItem
        onClick={() => {
          popover.onClose();
          window.open(`${instance_url}/lightning/setup/LabelWorkbenchTranslate/home`, '_blank');
        }}
      >
        <Iconify icon="tabler:language" width={16} />
        Workbench Translate
      </MenuItem>
      <MenuItem
        onClick={() => {
          popover.onClose();
          window.open(`${instance_url}/lightning/setup/LabelWorkbenchSetup/home`, '_blank');
        }}
      >
        <Iconify icon="icon-park-solid:workbench" width={16} />
        Translation Languages
      </MenuItem>
      {row.status === 'active' ? (
        <MenuItem
          onClick={() => {
            popover.onClose();
            deactivateUserEnv();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" width={16} sx={{ color: 'error.main' }} />
          Deactivate Environment
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            popover.onClose();
            activateUserEnv();
          }}
        >
          <Iconify icon="solar:home-add-bold" width={16} />
          Activate Environment
        </MenuItem>
      )}
    </CustomPopover>
  );

  return (
    <>
      {renderPrimary}
      {renderPopover}
      {renderLanguages}
      <EnvQuickEditForm
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        environment={row}
        handleEdit={handleEdit}
      />
      <RetrieveLanguagesInfoMessage
        open={isRetrieveLanguageInfo.value}
        onClose={isRetrieveLanguageInfo.onFalse}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        // title="Delete"
        title={row.api_name}
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        }
      />
    </>
  );
}
