import { useCallback, useEffect, useState } from 'react';
// hooks
import { useDashboardContext } from 'src/context/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
// @mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Alert, AlertColor, AlertTitle, Container } from '@mui/material';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedAction,
  TableProps,
  getComparator,
} from 'src/components/table';
import { useSettingsContext } from 'src/components/settings';
import { ITableFilters, ITreeView } from 'src/types/translatable';
import { IOrgCredentialTableFilterValue } from 'src/types/orgCredential';
import TableFiltersResult from 'src/sections/glossary/table-filters-result';
import { ITreeItemAllMetadata } from 'src/assets/data/treeViewMetadata';
//
import TableToolbar from 'src/sections/credentials/table-toolbar';
import RetrieveTableRow from './retrieve-table-row';

//
// ----------------------------------------------------------------------

const TABLE_HEAD = [{ length: 30 }, { id: 'metaData', label: 'Metadata' }];

const defaultFilters: ITableFilters = {
  name: '',
  status: 'all',
  startDate: null,
  endDate: null,
};
// ----------------------------------------------------------------------
type Props = {
  table: TableProps;
  disabled?: boolean;
};
export default function RetrieveListView({ table, disabled }: Props) {
  const { currentOrg } = useDashboardContext();

  useEffect(() => {
    table.setSelected(currentOrg?.defaultMetadata!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settings = useSettingsContext();
  const [limitMessage, setLimitMessage] = useState(<></>);
  //   const table = useTable({ defaultOrderBy: 'orderNumber', defaultRowsPerPage: 30 });
  const changeLimitMessage = (severity: AlertColor | undefined) => {
    setLimitMessage(
      <Alert severity={severity} variant="outlined">
        <AlertTitle>Limit Reached</AlertTitle>
        To select more than 5 values, please upgrade to a premium account.
      </Alert>
    );
  };
  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const dataFiltered = applyFilter({
    inputData: ITreeItemAllMetadata,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 40 : 72;

  const canReset =
    !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length;

  const handleFilters = useCallback(
    (name: string, value: IOrgCredentialTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  useEffect(() => {
    if (table.selected.length < 5) {
      setLimitMessage(<></>);
    }
  }, [table]);
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        {limitMessage}

        <TableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
        />
        {canReset && (
          <TableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered?.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={dataInPage.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                false,
                dataInPage.map((row) => row.id)
              )
            }
          />

          <Table size={table.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={dataInPage.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
            />

            <TableBody>
              {dataInPage.map((row) => (
                <RetrieveTableRow
                  disabled={disabled}
                  row={row}
                  selected={table.selected.includes(row.id)}
                  onSelectRow={() => {
                    table.onSelectRow(row.id);
                  }}
                />
              ))}

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePaginationCustom
          count={dataFiltered?.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        /> */}
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: ITreeView[] | [];
  comparator: (a: any, b: any) => number;
  filters: ITableFilters;
  dateError: boolean;
}) {
  if (Array.isArray(inputData)) {
    const { status, name, startDate, endDate } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
      inputData = inputData.filter(
        (item) => item.metadata_type_label.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    }
  }

  return inputData;
}
