import { useEffect } from 'react';
import { m } from 'framer-motion';
//
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { Alert, Link, Typography } from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
//
import AlertComponent from 'src/sections/metadataTranslator/view/mt-table/translatables/alert-component';
import { RouterLink } from 'src/routes/components';
import CredentialsView from 'src/sections/credentials/view/view';
import TranslationEngineView from 'src/sections/translationEngine/view';
import useSetupWizardHandler from './util/setup-wizard-handler';
//
import Iconify from '../iconify';
// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  wizardStatus: string;
  wizardCompleted: VoidFunction;
};

const steps = [
  'Welcome!',
  'Prerequisites for Translation',
  'Create Environment and Credential',
  'Retrieve Metadata',
  'Enable/Disable Translation Engines',
  'Have Fun!',
];

const stepStatusMap: Record<string, number> = {
  started: 0,
  setPrerequisites: 1,
  credentialSetup: 2,
  credInProgress: 2,
  credSetupFinished: 2,
  retrieveTranslation: 3,
  retrieveSetupFinished: 3,
  translationEngine: 4,
  finished: 5,
};

export default function WizardDialog({ open, onClose, wizardStatus, wizardCompleted }: Props) {
  const {
    environments,
    activeStep,
    setActiveStep,
    handleBack,
    handleNext,
    handleReset,
    setFinishStatus,
    newWizardStatus,
  } = useSetupWizardHandler();
  const theme = useTheme();

  useEffect(() => {
    if (wizardStatus) {
      setActiveStep(stepStatusMap[wizardStatus]);
    } else {
      setActiveStep(0);
    }
  }, [wizardStatus, setActiveStep]);

  const handleFinish = () => {
    setFinishStatus();

    wizardCompleted();
    onClose();
  };

  const renderTextInfo = (step: number) => {
    if (step === 2 || step === 4) {
      return (
        <Paper
          sx={{
            p: 3,
            my: 3,
            minHeight: 120,
            bgcolor: alpha(theme.palette.grey[500], 0.12),
          }}
        >
          <Box justifySelf="center" sx={{ width: '80%' }}>
            {step === 2 && (
              <>
                <p style={{ fontSize: '1.2em', lineHeight: '1.8', margin: 0 }}>
                  <strong>Enable the Translation Feature for Multilingual Support:</strong>
                  <br />
                  Ensure the translation feature is enabled in your Salesforce Orgs for seamless
                  multilingual functionality.
                  <br />
                  <strong>Standardize API Names for Seamless DevOps Integration:</strong>
                  <br />
                  Connect all environments in your development pipeline, such as DEV, UAT, and PROD.
                  <br />
                  Using consistent API names across your deployment tooling. This ensures:
                </p>
                <ul style={{ fontSize: '1.2em', lineHeight: '1.8', margin: 0 }}>
                  <li>
                    <strong>Consistency:</strong> Avoid errors with uniform naming conventions
                    across environments (e.g., DEV, UAT, PROD).
                  </li>
                  <li>
                    <strong>Streamlined Integration:</strong> Tools like Salesforce DevOps Center
                    work seamlessly with consistent naming practices.
                  </li>
                </ul>
              </>
            )}
            {step === 4 && (
              <ul style={{ fontSize: '1.2em', lineHeight: '1.8', margin: 0 }}>
                <li>Translation Engines allow you to choose services for your translations.</li>
                <li>The default state is active for all translation engines.</li>
                <li>
                  You can activate or deactivate an engine in the{' '}
                  <Iconify icon="solar:pen-bold" width={14} /> (edit engine) dialog.
                </li>
                <li>
                  For more information:{' '}
                  <a href={paths.docs.setup.translationEngine}>Translation Engine Documentation</a>.
                </li>
              </ul>
            )}
          </Box>
        </Paper>
      );
    }
    return null;
  };

  const stepContent = (step: number) => {
    switch (step) {
      case 2:
        return <CredentialsView key="step2" wizzardSetupType={newWizardStatus} />;
      case 3:
        return <CredentialsView key="step3" wizzardSetupType={newWizardStatus} />;
      case 4:
        return <TranslationEngineView key="step4" wizzardSetupType={newWizardStatus} />;
      default:
        return null;
    }
  };

  const renderPrerequisitesPage = (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          borderRadius: 2,
          pb: { xs: 5, md: 0 },
        }}
      >
        <Box sx={{ pr: 5 }}>
          <Typography variant="h2">Prerequisites for Translation</Typography>
          <Paper
            sx={{
              p: 3,
              my: 3,
              bgcolor: alpha(theme.palette.grey[500], 0.12),
            }}
          >
            <div style={{ fontSize: '1.2em', lineHeight: '1.2', margin: 0 }}>
              <p><b>In order to translate, retrieve, and deploy properly, you need to:</b></p>
              <ol style={{ lineHeight: '2.0em' }}>
                <li>
                  Enable the Translation Workbench by following the steps in the{' '}
                  <Link href='https://help.salesforce.com/s/articleView?id=platform.customize_wbench.htm&language=en_US&type=5' underline="always" target="_blank">Salesforce documentation</Link>.
                </li>
                <li>
                  Activate the languages you need in Salesforce. See{' '}
                  <Link href='https://help.salesforce.com/s/articleView?id=platform.adding_and_editing_translated_languages.htm&language=en_US&type=5' underline="always" target="_blank">Salesforce documentation</Link> for more information.
                </li>
              </ol>
            </div>
          </Paper>
        </Box>
        <Box
          component={m.img}
          alt="rocket"
          src="/assets/illustrations/characters/character_3.png"
          sx={{
            maxWidth: 180,
            p: 5,
          }}
        />
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
          Close
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleNext} variant="contained">
          Next
        </Button>
      </Box>
    </>
  )

  const renderWelcomePage = (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          borderRadius: 2,
          pb: { xs: 5, md: 0 },
        }}
      >
        <Box sx={{ pr: 5 }}>
          <Typography variant="h2">Welcome to SimpleTranslate</Typography>
          <Paper
            sx={{
              p: 3,
              my: 3,
              bgcolor: alpha(theme.palette.grey[500], 0.12),
            }}
          >
            <ul style={{ lineHeight: '1.8em' }}>
              <li>Your Go-To Tool for Metadata and Data Translations in Salesforce!</li>
              <li>
                Effortlessly get started by following the steps in the Setup Wizard and simplify the
                process of translating your Salesforce Orgs.
              </li>
            </ul>
          </Paper>
          <Alert
            severity="success"
            variant="outlined"
            icon={<Iconify icon="bi:magic" color={theme.palette.success.main} />}
            sx={{ mb: 3 }}
          >
            Challenge: Let&apos;s configure SimpleTranslate together in less than a minute!
          </Alert>
        </Box>
        <Box
          component={m.img}
          alt="rocket"
          src="/assets/illustrations/characters/character_3.png"
          sx={{
            maxWidth: 180,
            p: 5,
          }}
        />
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
          Close
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleNext} variant="contained">
          Start
        </Button>
      </Box>
    </>
  );

  const renderFinishPage = (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          borderRadius: 2,
          pb: { xs: 5, md: 0 },
        }}
      >
        <Box sx={{ pr: 5 }}>
          <Typography variant="h2">You are ready to translate!</Typography>
          <Paper
            sx={{
              p: 3,
              my: 3,
              bgcolor: alpha(theme.palette.grey[500], 0.12),
            }}
          >
            <ul style={{ lineHeight: '1.8em' }}>
              <li>Congrats, you have set up everything needed to start working in a simple way.</li>
              <li>You will be notified when the retrieve metadata is complete.</li>
              <li>
                Learn more: <Link href={paths.docs.overview}>Simple Translate Documentation</Link>
              </li>
            </ul>
          </Paper>
          {environments.map((env) => (
            <AlertComponent environemt={env} />
          ))}
        </Box>
        <Box
          component={m.img}
          animate={{
            y: [-20, 0, -20],
          }}
          transition={{ duration: 4, repeat: Infinity }}
          alt="rocket"
          src="/assets/illustrations/characters/character_2.png"
          sx={{
            maxWidth: 180,
            p: 5,
          }}
        />
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" onClick={handleReset} sx={{ mr: 1 }}>
          Try Again
        </Button>
        <Button
          variant="contained"
          component={RouterLink}
          href={paths.dashboard.root}
          onClick={handleFinish}
        >
          Finish
        </Button>
      </Box>
    </>
  );

  const renderContent = () => {
    if (activeStep === 0) {
      return renderWelcomePage;
    }
    if (activeStep === 1) {
      return renderPrerequisitesPage;
    }
    if (activeStep === steps.length - 1) {
      return renderFinishPage;
    }

    return (
      <>
        <Stack
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}
          sx={{
            pb: 4,
            pl: 4,
          }}
        >
          <Typography variant="h3" sx={{ pb: 1 }}>
            Setup Wizard
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ flexGrow: 1, maxWidth: '100%' }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
        {renderTextInfo(activeStep)}
        <Paper
          sx={{
            p: 3,
            my: 3,
            minHeight: 120,
            bgcolor: alpha(theme.palette.grey[500], 0.12),
          }}
        >
          {stepContent(activeStep)}
        </Paper>
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="outlined"
            disabled={activeStep === 1}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" onClick={onClose} sx={{ mr: 1 }}>
            Close
          </Button>
          <Button onClick={handleNext} variant="contained">
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: activeStep === steps.length - 1 || activeStep === 0 || activeStep === 1 ? '60%' : '90%',
          maxHeight: '90%',
        },
      }}
    >
      <Box sx={{ p: 5 }}>{renderContent()}</Box>
    </Dialog>
  );
}
