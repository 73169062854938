import { useEffect, useMemo, useState } from 'react';
// hooks
import { useAuthContext } from 'src/auth/hooks';
// types
import { IOrganization } from 'src/types/organization';
import { IUser } from 'src/types/user';
// routes
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
//
import useFetcher, { endpoints } from 'src/utils/axios';
import { DashBoardContext } from './context';

type Props = {
  children: React.ReactNode;
};

export function DashBoardProvider({ children }: Props) {
  const [allOrgs, setAllOrgs] = useState<IOrganization[]>();
  const [currentOrg, setCurrentOrgs] = useState<IOrganization>();
  const { fetchData } = useFetcher();
  const router = useRouter();
  
  useEffect(() => {
    if (currentOrg?.org_id) {
      const newAllOrgs = allOrgs?.map((org: IOrganization) => {
        if (org.org_id === currentOrg.org_id) {
          return currentOrg;
        }
        return org;
      });
      setAllOrgs(newAllOrgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg]);
  
  useEffect(() => {
    const fetchDataAndSetContext = async () => {
      try {
        const orgsResponse = await fetchData(endpoints.org.get);
        const user: IUser = await fetchData(endpoints.users.getMe);

        const defaultOrg =
          orgsResponse.find((org: IOrganization) => org.org_id === user.org_id) || orgsResponse[0];

        if (orgsResponse) {
          setAllOrgs(orgsResponse);

          const storedOrgID = localStorage.getItem('selectedOrganization');
          if (storedOrgID && storedOrgID !== 'undefined') {
            const parsedStoredOrgID = JSON.parse(storedOrgID);
            if (orgsResponse.some((org: IOrganization) => org.org_id === parsedStoredOrgID)) {
              const parsedStoredOrg = orgsResponse.find(
                (org: IOrganization) => org.org_id === parsedStoredOrgID
              );
              setCurrentOrgs(parsedStoredOrg);
            } else {
              setCurrentOrgs(defaultOrg);
            }
          } else {
            setCurrentOrgs(defaultOrg);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        const currentUrl = window.location.href;
        router.push(`${paths.maintenance}?redirect=${encodeURIComponent(currentUrl)}`);
      }
    };

    // Check if context is null to avoid unnecessary fetches
    if (allOrgs === undefined) {
      fetchDataAndSetContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memorizedValue = useMemo(
    () => ({
      allOrgs,
      setAllOrgs,
      currentOrg,
      setCurrentOrgs,
    }),
    [allOrgs, setAllOrgs, currentOrg, setCurrentOrgs]
  );
  return <DashBoardContext.Provider value={memorizedValue}>{children}</DashBoardContext.Provider>;
}
