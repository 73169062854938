import { FC, lazy, ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen, SplashScreen } from 'src/components/loading-screen';
import { DashBoardProvider } from 'src/context/dashboard/provider';
import { RealTimeProvider } from 'src/context/realtimeUpdates/provider';
import LoadingScreenLogo from 'src/components/loading-screen/loading-screen-logo';
import ProfileView from 'src/sections/user/view/profile-information-view';

// ----------------------------------------------------------------------

// MetadataTranslator
const NewMetadataTranslator = lazy(() => import('src/pages/dashboard/MetadataTranslator'));
// Jobs
const Logs = lazy(() => import('src/pages/dashboard/jobs'));
const MTJobs = lazy(() => import('src/pages/dashboard/MTjobs'));
const MTJobsDetails = lazy(() => import('src/pages/dashboard/MTJobDetails'));
// Glossary
const GlossaryList = lazy(() => import('src/pages/dashboard/glossaryList'));

const GlossaryListItem = lazy(() => import('src/pages/dashboard/glossaryListItem'));

const CompanyInformation = lazy(() => import('src/pages/dashboard/companyInformation/account'));
// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/user'));
const SecurityPage = lazy(() => import('src/pages/dashboard/Security'));
const ApprovalPage = lazy(() => import('src/pages/dashboard/approval'));

const TranslationEnginePage = lazy(() => import('src/pages/dashboard/translationEngine'));

// Credentials
const Credentials = lazy(() => import('src/pages/setup/credentials'));

// ----------------------------------------------------------------------
const queryParameters = new URLSearchParams(window.location.search);

const inviteKey = queryParameters.get('inviteKey');

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard inviteKey={inviteKey}>
        <DashBoardProvider>
          <RealTimeProvider>
            <DashboardLayout>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          </RealTimeProvider>
        </DashBoardProvider>
      </AuthGuard>
    ),
    children: [
      { element: <NewMetadataTranslator />, index: true },
      // { path: 'new', element: <NewMetadataTranslator /> },
      { path: 'Logs', element: <Logs /> },
      { path: 'MTJobs', element: <MTJobs /> },
      { path: 'MTJobDetails', element: <MTJobsDetails /> },
      {
        path: 'glossary',
        children: [
          { path: '', element: <GlossaryList /> },
          { path: ':id', element: <GlossaryListItem /> },
        ],
      },
      // { path: 'glossary/*', element: <GlossaryListItem /> },
      { path: 'credentials', element: <Credentials /> },
      { path: 'companyInformation', element: <CompanyInformation /> },
      { path: 'security', element: <SecurityPage /> },
      { path: 'approval', element: <ApprovalPage /> },
      { path: 'translationEngine', element: <TranslationEnginePage /> },
      { path: 'user', element: <UserListPage /> },
      { path: 'user/account', element: <ProfileView /> },
    ],
  },
];
