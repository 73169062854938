import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SplashScreen from 'src/components/loading-screen/splash-screen';
// layouts
import MainLayout from 'src/layouts/main';
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------
export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const Maintenance = lazy(() => import('src/pages/maintenance'));
const SalesforceRedirect  = lazy(() => import('src/pages/auth/salesforce/salesforce-redirect'));
// Article List
const ArticlesListPage = lazy(() => import('src/pages/articles/list'));
// Aticle Blogs
const TranslateFastBlogPage  = lazy(() => import('src/pages/articles/blogs/how-to-translate-fast'));
const STVsSalesforceTranslationBlogPage  = lazy(() => import('src/pages/articles/blogs/st-vs-salesforce-translation'));
const AvailableMetadataBlogPage  = lazy(() => import('src/pages/articles/blogs/available-metadatas-in-st'));
const HowToSetupSTBlogPage  = lazy(() => import('src/pages/articles/blogs/how-to-setup-st'));
const GetStartedWithSTBlogPage  = lazy(() => import('src/pages/articles/blogs/get-started-with-st'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: '404', element: <Page404 /> },
      { path: 'maintenance', element: <Maintenance /> },
      { path: 'redirect', element: <SalesforceRedirect /> },
    ],
  },
  {
    path: 'articles',
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      // List
      { element: <ArticlesListPage />, index: true },
      // Blogs
      { path: 'translate-fast-with-simple-translate', element: <TranslateFastBlogPage /> }, 
      { path: 'salesforce-translation-vs-simple-translate', element: <STVsSalesforceTranslationBlogPage /> }, 
      { path: 'available-metadata-in-simple-translate', element: <AvailableMetadataBlogPage /> }, 
      { path: 'get-started-with-simple-translate', element: <GetStartedWithSTBlogPage /> }, 
      { path: 'how-to-setup-simple-translate', element: <HowToSetupSTBlogPage /> },
    ],
  },
];
