// utils
import { paramCase } from 'src/utils/change-case';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  DOCS: '/docs',
  SUPPORT: '/support',
  LEGAL: '/legal',
  ARTICLES: '/articles',
};

// ----------------------------------------------------------------------

export const paths = {
  /* to be changed &checked if really needed */
  comingSoon: '/coming-soon',
  page403: '/403',
  page500: '/500',
  components: '/components',
  
  /* to be changed */
  
  maintenance: '/maintenance',
  page404: '/404',

  auth: {
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
  },

  // SUPPORT
  supportPath: {
    root: ROOTS.SUPPORT,
    faqs: `${ROOTS.SUPPORT}/faqs`,
    contact: `${ROOTS.SUPPORT}/contact-us`,
  },


  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    metadataTranslator: `${ROOTS.DASHBOARD}/metadataTranslator`,
    jobs: `${ROOTS.DASHBOARD}/jobs`,
    metadataTranslateJob: `${ROOTS.DASHBOARD}/MTJobs`,
    metadataTranslateJobDetails: `${ROOTS.DASHBOARD}/MTJobDetails`,
    companyInformation: `${ROOTS.DASHBOARD}/companyInformation`,
    glossary: `${ROOTS.DASHBOARD}/glossary`,
    credentials: `${ROOTS.DASHBOARD}/credentials`,
    approval: `${ROOTS.DASHBOARD}/approval`,
    log: `${ROOTS.DASHBOARD}/Logs`,
    translationEngine: `${ROOTS.DASHBOARD}/translationEngine`,
    security: `${ROOTS.DASHBOARD}/security`,
    group: {
      root: `${ROOTS.DASHBOARD}/group`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      list: `${ROOTS.DASHBOARD}/user`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
  },

  // LEGAL
  legal: {
    root: ROOTS.LEGAL,
    impressum: `${ROOTS.LEGAL}/impressum`,
    disclaimer: `${ROOTS.LEGAL}/disclaimer`,
    eeaStandardContactualClauses: `${ROOTS.LEGAL}/eea-standard-contractual-clauses`,
    cookiePolicy: `${ROOTS.LEGAL}/cookie-policy`,
    privacyPolicy: `${ROOTS.LEGAL}/privacy-policy`,
    dataProcessingAgreement: `${ROOTS.LEGAL}/data-processing-agreement`,
    termsAndConditions: `${ROOTS.LEGAL}/terms-and-conditions`,
  },

  // DOCS
  docs: {
    root: ROOTS.DOCS,
    overview: `${ROOTS.DOCS}/overview`,
    user: {
      root: `${ROOTS.DOCS}/user`,
      roles: `${ROOTS.DOCS}/user/roles`,
      register: `${ROOTS.DOCS}/user/register`,
      invite: `${ROOTS.DOCS}/user/invite`,
      passwordReset: `${ROOTS.DOCS}/user/passwordReset`,
    },
    setup: {
      root: `${ROOTS.DOCS}/setup`,
      setupApproval: `${ROOTS.DOCS}/setup/approval`,
      orgCredentials: `${ROOTS.DOCS}/setup/orgCredentials`,
      translationEngine: `${ROOTS.DOCS}/setup/translationEngine`,
      glossary: `${ROOTS.DOCS}/setup/glossary`,
      notifications: `${ROOTS.DOCS}/setup/notifications`,
    },
    translationWorkbench: {
      root: `${ROOTS.DOCS}/translationWorkbench`,
      retrieveMetadata: `${ROOTS.DOCS}/translationWorkbench/retrieveMetadata`,
      filterMetadata: `${ROOTS.DOCS}/translationWorkbench/filterMetadata`,
      deployMetadata: `${ROOTS.DOCS}/translationWorkbench/deployMetadata`,
      translateMetadata: `${ROOTS.DOCS}/translationWorkbench/translateMetadata`,
      limit: `${ROOTS.DOCS}/translationWorkbench/limit`,
      tokenHistory: `${ROOTS.DOCS}/translationWorkbench/tokenHistory`,
    },
    MTJob: {
      root: `${ROOTS.DOCS}/MTJob`,
      createUpdateJob: `${ROOTS.DOCS}/MTJob/createUpdateJob`,
      status: `${ROOTS.DOCS}/MTJob/status`,
      compareOrgs: `${ROOTS.DOCS}/MTJob/compareOrgs`,
      orgsDeployment: `${ROOTS.DOCS}/MTJob/orgsDeployment`,
    },
    salesforce: {
      root: `${ROOTS.DOCS}/salesforce`,
      realTimeDataTranslation: `${ROOTS.DOCS}/salesforce/realTimeDataTranslation`,
      languageDetection: `${ROOTS.DOCS}/salesforce/languageDetection`,
      authenticationSalesforce: `${ROOTS.DOCS}/salesforce/authenticationSalesforce`,
      feedItemPostTranslation: `${ROOTS.DOCS}/salesforce/feedItemPostTranslation`,
      emailTranslation: `${ROOTS.DOCS}/salesforce/emailTranslation`,
      installPackage: `${ROOTS.DOCS}/salesforce/installPackage`,
      editRecordPage: `${ROOTS.DOCS}/salesforce/editRecordPage`,
      caseTranslation: `${ROOTS.DOCS}/salesforce/caseTranslation`
    },
    openAPIs: {
      root: `${ROOTS.DOCS}/openAPIs`,
      token: `${ROOTS.DOCS}/openAPIs/token`,
    },
    extensions: {
      root: `${ROOTS.DOCS}/extensions`,
      stCiCdComponent: `${ROOTS.DOCS}/extensions/stCiCdComponent`,
    },
  },
  
  // ARTICLES
  articles: {
    root: ROOTS.ARTICLES,
    translateFast: `${ROOTS.ARTICLES}/translate-fast-with-simple-translate`,
    stVsSalesforceTranslation: `${ROOTS.ARTICLES}/salesforce-translation-vs-simple-translate`,
    availableMetadata: `${ROOTS.ARTICLES}/available-metadata-in-simple-translate`,
    getStartedWithSt: `${ROOTS.ARTICLES}/get-started-with-simple-translate`,
    howToSetupSt: `${ROOTS.ARTICLES}/how-to-setup-simple-translate`,
  },
};
