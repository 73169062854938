import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import useFetcher, { endpoints } from 'src/utils/axios';
// routes
import { IUser } from 'src/types/user';
// _mock data
import { ORG_CREDENTIAL_STATUS_OPTIONS } from '../../../_mock';

// hooks
import { useBoolean } from '../../../hooks/use-boolean';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// types
import {
  IEnvironment,
  IOrgCredential,
  IOrgCredentialRow,
  IOrgCredentialTableFilterValue,
  ITableFilters,
} from '../../../types/orgCredential';
//
import TableRowMetadata from '../table-row-metadata';
import TableToolbar from '../table-toolbar';
import TableFiltersResult from '../table-filters-result';
// ----------------------------------------------------------------------

interface Props {
  credentials: IOrgCredential[];
  environments: IEnvironment[];
  onUpdateEnv: VoidFunction;
  users: IUser[];
  checkEnv: VoidFunction;
  wizzardSetupType?: string;
  setWizzardUpdate?: (status: string) => void;
}

const TABLE_HEAD = [
  { id: 'org_crediantial_name', label: 'Credential Name' },
  { id: 'api_name', label: 'API Name' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'env_name', label: 'Env Name' },
  { id: 'env_api_name', label: 'Env API Name' },
  { id: 'instance_url', label: 'Instance URL' },
  { id: 'status', label: 'Status' },
  { id: '', width: 88 },
];

const defaultFilters: ITableFilters = {
  name: '',
  status: 'Active',
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

export default function ListView({
  credentials,
  environments,
  onUpdateEnv,
  users,
  checkEnv,
  wizzardSetupType,
  setWizzardUpdate,
}: Props) {
  const { fetchData } = useFetcher();
  const table = useTable({ defaultOrderBy: 'orderNumber' });

  const confirm = useBoolean();

  const [listCredentials, setListCredentials] = useState<IOrgCredential[]>(credentials);
  useEffect(() => {
    setListCredentials(credentials);
  }, [credentials]);
  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const createIOrgCredentialRow = () => {
    const orgCredentialRow: IOrgCredentialRow[] = listCredentials.map((orgCred) => {
      const env_name = environments.filter((data) => data.env_id === orgCred.env_id)[0]?.name;
      const env_api_name = environments.filter((data) => data.env_id === orgCred.env_id)[0]
        ?.api_name;
      const createdBy = users
        .filter((user) => user.uid === orgCred.createdBy)
        .map((user) => user.displayName)[0];

      const credRow: IOrgCredentialRow = {
        ...orgCred,
        env_name,
        env_api_name,
        createdBy,
      };
      return credRow;
    });
    return orgCredentialRow;
  };

  const dataFiltered = applyFilter({
    inputData: createIOrgCredentialRow(),
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 40 : 72;

  const canReset =
    !!filters.name || filters.status !== 'all' || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IOrgCredentialTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );
  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleUpdateRow = useCallback(() => {
    fetchData([endpoints.credential.get, {}]).then((response: IOrgCredential[]) => {
      setListCredentials(response);
    });
    checkEnv();
  }, [fetchData, checkEnv]);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );
  const handleDeleteRow = useCallback(
    (cred_id: string) => {
      fetchData([endpoints.credential.delete, { params: { cred_id } }]).then(() => {});
    },
    [fetchData]
  );
  const handleDeleteRows = () => {
    table.selected.forEach((cred_id) => {
      fetchData([endpoints.credential.delete, { params: { cred_id } }]).then(() => {});
    });
  };
  return (
    <>
      <CustomBreadcrumbs
        heading="Credentials"
        links={[{ name: '' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card
        sx={{
          m: 1,
          p: 6,
        }}
      >
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {ORG_CREDENTIAL_STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant="soft"
                  color={
                    (tab.value === 'Active' && 'success') ||
                    (tab.value === 'Inactive' && 'error') ||
                    'default'
                  }
                >
                  {tab.value === 'Active' &&
                    listCredentials.filter((data) => data.cred_state === 'Active').length}
                  {tab.value === 'Inactive' &&
                    listCredentials.filter((data) => data?.cred_state === 'Inactive').length}
                </Label>
              }
            />
          ))}
        </Tabs>

        <TableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
        />

        {canReset && (
          <TableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={credentials.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {dataInPage.map((row, index) => (
                  <TableRowMetadata
                    key={row.credentials_id}
                    row={row}
                    onDeleteRow={(id: string) => handleDeleteRow(id)}
                    selected={table.selected.includes(row.credentials_id)}
                    onSelectRow={() => table.onSelectRow(row.credentials_id)}
                    onViewRow={() => null}
                    onUpdateRow={handleUpdateRow}
                    onUpdateEnv={onUpdateEnv}
                    wizzardSetupType={index === 0 ? wizzardSetupType : undefined}
                    setWizzardUpdate={setWizzardUpdate}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, credentials.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: IOrgCredentialRow[] | [];
  comparator: (a: any, b: any) => number;
  filters: ITableFilters;
  dateError: boolean;
}) {
  if (Array.isArray(inputData)) {
    const { status, name } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
      inputData = inputData.filter(
        (translation) =>
          translation.credentials_id.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.org_crediantial_name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.cred_state.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.env_api_name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.env_name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
          translation.instance_url.toLowerCase().indexOf(name.toLowerCase()) !== -1
      );
    }

    if (status !== 'all') {
      inputData = inputData.filter((order) => order.cred_state === status);
    }
  }

  return inputData;
}
