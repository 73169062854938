import { useCallback, useEffect, useState, useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// components
import Label from 'src/components/label';
import useFetcher, { endpoints } from 'src/utils/axios';
import { IEnvironment, IOrgCredential } from 'src/types/orgCredential';
import { useSettingsContext } from 'src/components/settings';
import { useDashboardContext, useRealTimeContext } from 'src/context/hooks';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { Button, ButtonGroup, Grid, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { IUser } from 'src/types/user';
import { LoadingScreen } from 'src/components/loading-screen';
import usePermission from 'src/permission/has-permission';
import PermissionDenied from 'src/components/table/permission-denied';
import ListViewEnvironment from './list-view-environment';
import ListView from './list-view';
import CredentialsQuickCreateForm from '../credentials-quick-create-form';
import EnvironmentQuickCreateForm from '../environment-quick-create-form';
import { MANAGE_SETUP } from '../../../permission/all_permissions';
// ----------------------------------------------------------------------

type WizzardProps = {
  wizzardSetupType?: string;
};

export default function CredentialsView({ wizzardSetupType }: WizzardProps) {
  const credsCreate = useBoolean();
  const envCreate = useBoolean();
  const settings = useSettingsContext();
  const { fetchData, updateData } = useFetcher();
  const dashBoardContext = useDashboardContext();
  const isLoading = useBoolean(true);
  const [orgCredentials, setOrgCredentials] = useState<IOrgCredential[]>([]);
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);
  const [newWizzardStatus, setNewWizzardStatus] = useState(wizzardSetupType);
  const queryParameters = new URLSearchParams(window.location.search);
  const code: string | null = queryParameters.get('code');
  const state: string | null = queryParameters.get('state');
  const { allowedPermission, isPermissionLoading } = usePermission(MANAGE_SETUP.MANAGE_SETUP);
  const theme = useTheme();

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const router = useRouter();
  const [users, setUsers] = useState<IUser[]>([]);
  const { realTimeMessages } = useRealTimeContext();

  const getOrgCredentials = () => {
    if (dashBoardContext.currentOrg) {
      fetchData(endpoints.credential.get)
        .then((response: IOrgCredential[]) => {
          setOrgCredentials(response);
          isLoading.onFalse();
        })
        .catch((error) => {
          console.error('Error fetching logs:', error);
          isLoading.onFalse();
        });
    }
  };
  const getEnvironment = () => {
    if (dashBoardContext.currentOrg) {
      fetchData(endpoints.environment).then((response) => {
        setEnvironments(response);
      });
    }
  };
  const getUser = () => {
    if (dashBoardContext.currentOrg) {
      fetchData(endpoints.users.get).then((response) => {
        setUsers(response);
      });
    }
  };
  const handleUpdateCredFromEnv = useCallback(
    (updatedRow: IEnvironment) => {
      const updatedCredentials = orgCredentials.map((cred) => {
        if (cred.env_id === updatedRow.env_id) {
          return {
            ...cred,
            status: updatedRow.status === 'active' ? 'Connected' : 'Inactive',
            cred_state: updatedRow.status === 'active' ? 'Active' : 'Inactive',
          };
        }
        return cred;
      });

      updatedCredentials
        .filter((cred) => cred?.env_id === updatedRow.env_id)
        .forEach((cred) => {
          updateData(endpoints.credential.update, [cred]);
        });
      setOrgCredentials(updatedCredentials);
    },
    [orgCredentials, updateData]
  );
  const handleWizzardUpdate = useCallback(
    (status?: string) => {
      if (dashBoardContext.currentOrg) {
        setNewWizzardStatus(status || dashBoardContext?.currentOrg?.setupWizzardStatus);
      }
    },
    [dashBoardContext.currentOrg]
  );

  useEffect(() => {
    if (code && state && dashBoardContext.currentOrg) {
      fetchData([endpoints.credential.setCreds, { params: { code, state } }]).then(() => {
        router.push(paths.dashboard.credentials);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashBoardContext.currentOrg]);

  useEffect(() => {
    if (dashBoardContext.currentOrg) {
      getUser();
      getEnvironment();
      getOrgCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashBoardContext.currentOrg]);
  useEffect(() => {
    if (realTimeMessages && dashBoardContext.currentOrg) {
      if (realTimeMessages[0]?.message === 'refresh_data') {
        getEnvironment();
        getOrgCredentials();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMessages]);

  return (
    <>
      <CustomBreadcrumbs
        heading="Environments and Credential"
        links={[{ name: '' }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading.value || isPermissionLoading || allowedPermission === null ? (
          <LoadingScreen />
        ) : allowedPermission && !isPermissionLoading ? (
          <>
            <Grid container spacing={0.5}>
              <Container maxWidth={settings.themeStretch ? false : 'lg'}>
                {newWizzardStatus !== 'retrieveTranslation' &&
                  newWizzardStatus !== 'retrieveSetupFinished' && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <CustomBreadcrumbs
                        heading="Environments"
                        links={[{ name: '' }]}
                        action={
                          <ButtonGroup variant="contained" color="primary">
                            <Tooltip
                              title={
                                <div style={{ padding: 5 }}>
                                  <p style={{ lineHeight: '1.5', margin: 0, fontSize: '1.0rem' }}>
                                    Click &quot;Create Credential&quot; to connect to your
                                    Salesforce Organization
                                  </p>
                                  <p
                                    style={{
                                      lineHeight: '1.8',
                                      margin: 0,
                                      fontSize: '0.8rem',
                                      paddingTop: '0.6em',
                                    }}
                                  >
                                    Environments and credentials are utilized to connect
                                    SimpleTranslate with a Salesforce organization, enabling the
                                    proper retrieval and deployment of translations.
                                  </p>
                                </div>
                              }
                              open={
                                (newWizzardStatus === 'credentialSetup' && !credsCreate.value) ||
                                false
                              }
                              arrow
                              placement="top"
                            >
                              <Button
                                color="primary"
                                variant="contained"
                                ref={buttonRef}
                                startIcon={<Iconify icon="mingcute:add-line" />}
                                onClick={() => credsCreate.onTrue()}
                              >
                                Create Credential
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        }
                        sx={{
                          mb: { xs: 3, md: 5 },
                        }}
                      />
                    </Grid>
                  )}
                {newWizzardStatus !== 'retrieveTranslation' &&
                  newWizzardStatus !== 'retrieveSetupFinished' && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ListViewEnvironment
                        environments={environments}
                        onUpdateCreds={handleUpdateCredFromEnv}
                      />
                    </Grid>
                  )}
                {newWizzardStatus !== 'credentialSetup' &&
                  newWizzardStatus !== 'credSetupFinished' && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ListView
                        credentials={orgCredentials}
                        environments={environments}
                        onUpdateEnv={getEnvironment}
                        users={users}
                        checkEnv={getEnvironment}
                        wizzardSetupType={newWizzardStatus}
                        setWizzardUpdate={handleWizzardUpdate}
                      />
                    </Grid>
                  )}
              </Container>
            </Grid>
            <CredentialsQuickCreateForm
              open={credsCreate.value}
              onClose={credsCreate.onFalse}
              wizzardSetupType={newWizzardStatus}
              onWizzardUpdate={handleWizzardUpdate}
            />
            <EnvironmentQuickCreateForm open={envCreate.value} onClose={envCreate.onFalse} />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-boolean-value
          <PermissionDenied notFound={true} />
        )
      }
    </>
  );
}
